<template>
    <v-card :loading="loading" class="mx-auto">
        <template slot="progress">
            <v-progress-linear
                color="teal darken-4"
                height="10"
                indeterminate
            ></v-progress-linear>
        </template>
        <v-card-title class="accent-text">
            <div class="accent-text">Shipping Bill Duplicates</div>
        </v-card-title>
        <v-card-text class="mt-5">
            <v-row>
                <v-col cols="12" sm="3">
<!--                    <v-btn outlined color="secondary" dark :disabled="loading" v-on:click="loadDuplicateCount()" v-if="duplicateCount < 1"><v-icon>mdi-counter</v-icon>  Count Duplicate</v-btn>-->
                    <v-btn outlined color="secondary" dark :disabled="loading" v-on:click="deleteDuplicates()"><v-icon>mdi-delete</v-icon>  Delete Duplicates</v-btn>
                </v-col>
                <v-col cols="12" sm="4">
                    <v-alert border="top" color="red lighten-2" dark dense v-if="duplicateCount != null && !loading">{{ duplicateCount }} Duplicates deleted</v-alert>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    //import Vue from "vue";
    import Vue from "vue";

    export default {
        name: "ShippingBillsDuplicates_view",

        data: ()=>({
            loading: false,
            duplicateCount: null,
        }),
        methods:{
            /*loadDuplicateCount: async function () {
                this.loading = true;
                this.$axios.get(Vue.prototype.$globalSettings.api.endpoints.report.sbDuplicate, {params: {type: 'count' }})
                    .then((response) => {
                        console.log(response.data);
                        this.loading = false;
                        this.duplicateCount = response.data.DuplicateCount;
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$globalHelpers.processHttpErrorsToast(error);
                    })
            },*/
            deleteDuplicates: async function () {
                this.loading = true;
                this.$axios.get(Vue.prototype.$globalSettings.api.endpoints.report.sbDuplicate, {params: {type: 'delete' }})
                    .then((response) => {
                        console.log(response.data);
                        this.loading = false;
                        this.duplicateCount = response.data.DuplicateDeleteCount;
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$globalHelpers.processHttpErrorsToast(error);
                    })
            },
        },
        created() {
            //this.loadDuplicateCount();
        }
    }
</script>

<style scoped>

</style>